import {createTheme} from '@mui/material/styles';
import Khand from './assets/fonts/Khand-Regular.ttf';


const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 905,
    lg: 1240,
    xl: 1440,
  },
};


export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 905,
      lg: 1240,
      xl: 1440,
    },
  },

  palette: {
    fqRed: {
      main: '#F85467',
      contrastText: 'white',
    },
    menuMainPrimary: {
      main: '#32292F',
      contrastText: 'white',
    },
    menuMainSecondary: {
      main: '#32292F',
      contrastText: 'white',
    },
    menuMainTertiary: {
      main: '#32292F',
      contrastText: 'white',
    },
  },
  myButton: {
    backgroundColor: '#11cb5f',
    color: '#f31111',
    fontSize: '1rem',
    fontWeight: 'bold',
    padding: '0.5rem 1rem',
    border: 'none',
  },
  logoLg: {
    height: '3rem',
    width: 'auto',
  },
  logoSm: {
    height: '1.5rem',
    width: 'auto',
  },
  typography: {
    h1: {
      // fontSize: '13rem',
      [`@media screen and (min-width: ${breakpoints.values.xs}px)`]: {
        fontFamily: 'Respondent',
        fontStyle: 'italic',
        fontSize: '30px',
        fontWeight: '300px',
        lineHeight: '38.1px',
        letter: '%1',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
        fontFamily: 'Respondent',
        fontStyle: 'italic',
        fontSize: '50px',
        fontWeight: '300px',
        lineHeight: '55px',
        letter: '%1',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${breakpoints.values.md}px)`]: {
        fontFamily: 'Respondent',
        fontStyle: 'italic',
        fontSize: '60px',
        fontWeight: '300px',
        lineHeight: '76.2px',
        letter: '%1',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${breakpoints.values.lg}px)`]: {
        fontFamily: 'Respondent',
        fontStyle: 'italic',
        fontSize: '70px',
        fontWeight: '300px',
        lineHeight: '88.9px',
        letter: '%1',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
        fontFamily: 'Respondent',
        fontStyle: 'italic',
        fontSize: '80px',
        fontWeight: '300px',
        lineHeight: '101.6px',
        letter: '%1',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
    },
    h2: {
      // fontSize: '13rem',
      [`@media screen and (min-width: ${breakpoints.values.xs}px)`]: {
        fontFamily: 'Khand',
        fontWeight: '600px',
        fontSize: '16px',
        lineHeight: '24.46px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
        fontFamily: 'Khand',
        fontWeight: '600px',
        fontSize: '18px',
        lineHeight: '27.52px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${breakpoints.values.md}px)`]: {
        fontFamily: 'Khand',
        fontWeight: '600px',
        fontSize: '25px',
        lineHeight: '24px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${breakpoints.values.lg}px)`]: {
        fontFamily: 'Khand',
        fontWeight: '600px',
        fontSize: '30px',
        lineHeight: '45.87px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
        fontFamily: 'Khand',
        fontWeight: '600px',
        fontSize: '32px',
        lineHeight: '48.93px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
    },
    h1Plaj: {
      // fontSize: '13rem',
      [`@media screen and (min-width: ${breakpoints.values.xs}px)`]: {
        fontFamily: 'Respondent',
        fontWeight: '300px',
        fontStyle: 'italic',
        fontSize: '30px',
        lineHeight: '38.1px',
        letter: '%1',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
        fontFamily: 'Respondent',
        fontWeight: '300px',
        fontStyle: 'italic',
        fontSize: '30px',
        lineHeight: '33px',
        letter: '%1',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${breakpoints.values.md}px)`]: {
        fontFamily: 'Respondent',
        fontWeight: '300px',
        fontStyle: 'italic',
        fontSize: '40px',
        lineHeight: '50.8px',
        letter: '%1',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${breakpoints.values.lg}px)`]: {
        fontFamily: 'Respondent',
        fontWeight: '300px',
        fontStyle: 'italic',
        fontSize: '50px',
        lineHeight: '63.5px',
        letter: '%1',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
        fontFamily: 'Respondent',
        fontWeight: '300px',
        fontStyle: 'italic',
        fontSize: '60px',
        lineHeight: '76.1px',
        letter: '%1',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
    },
    t1: {
      // fontSize: '13rem',
      [`@media screen and (min-width: ${breakpoints.values.xs}px)`]: {
        fontFamily: 'Khand',
        fontWeight: '400px',
        fontSize: '12px',
        lineHeight: '18px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
        fontFamily: 'Khand',
        fontWeight: '400px',
        fontSize: '12px',
        lineHeight: '18px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${breakpoints.values.md}px)`]: {
        fontFamily: 'Khand',
        fontWeight: '400px',
        fontSize: '14px',
        lineHeight: '21px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${breakpoints.values.lg}px)`]: {
        fontFamily: 'Khand',
        fontWeight: '400px',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },
      [`@media screen and (min-width: ${breakpoints.values.xl}px)`]: {
        fontFamily: 'Khand',
        fontWeight: '400px',
        fontSize: '18px',
        lineHeight: '27px',
        textAlign: 'center',
        color: 'rgba(87, 87, 86, 0.8)',
      },

    },

  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Khand';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('RespondentLight'), local('RespondentLight'), url(${Khand}) format('otf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiButton: {
      variants: [
        {
          props: {variant: 'brownButton'},
          style: ({theme: t}) => (
            {
              backgroundColor: '#A48A7B',
              color: '#FAFAF3',
              borderRadius: '15px',
              '&:hover': {
                boxShadow: '0px 10px 20px 0px rgba(0,0,0,0.2), 0px 10px 20px 0px rgba(0,0,0,0.14), 0px 10px 20px 0px rgba(0,0,0,0.12)',
                backgroundColor: '#A48A7B',
                // backgroundColor: '#575756',
              },

              [t.breakpoints.between('xs', 'sm')]: {
                width: '96.79px',
                height: '30.61px',
                borderRadius: '5px',
                fontFamily: 'Khand',
                fontSize: '14px',
                lineHeight: '13.44px',
                align: 'center',


              },
              [t.breakpoints.between('sm', 'md')]: {
                width: '114.89px',
                height: '36.29px',
                borderRadius: '10px',
                fontFamily: 'Khand',
                fontSize: '18px',
                lineHeight: '17.28px',
                align: 'center',


              },
              [t.breakpoints.between('md', 'lg')]: {
                width: '165.15px',
                height: '52.12px',
                fontFamily: 'Khand',
                fontSize: '20px',
                lineHeight: '19.2px',
                align: 'center',


              },
              [t.breakpoints.between('lg', 'xl')]: {
                width: '182.74px',
                height: '57.65px',
                fontFamily: 'Khand',
                fontSize: '22px',
                lineHeight: '21.12px',
                align: 'center',


              },
              [t.breakpoints.up('xl')]: {
                width: '222px',
                height: '70px',
                fontFamily: 'Khand',
                fontSize: '24px',
                lineHeight: '23.04px',
                align: 'center',

              },
              // padding: "1rem",
              // borderRadius: "40px",
              // border: "1px dashed black",
              // backgroundColor: t.palette.fqRed.main,
              // color: t.palette.fqRed.contrastText
            }
          ),
        },
      ],
    },
    MuiTextField: {
      defaultProps: {
        fontFamily: 'Khand',
        size: 'small',
      },
    },
    MuiFormControl: {
      defaultProps: {
        fontFamily: 'Khand',
        size: 'small',
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontFamily: 'Khand',
          fontSize: '13px',
          top: 2,
        },
      },
      styleOverrides: {
        shrink: ({ownerState, theme}) => (
          {
            ...(
              ownerState.shrink && {
                fontFamily: 'Khand',
                fontSize: '1rem !important',
                top: '-1 !important',
              }
            ),
          }
        ),
      },
    },
  },


});

