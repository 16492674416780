import {
  COMPANY_INFO_FETCH_FAILURE,
  COMPANY_INFO_FETCH_SUCCESS,
  COMPANY_INFO_PDF_MENU_FAILURE,
  COMPANY_INFO_PDF_MENU_SUCCESS,
  COMPANY_INFO_STORE_ID_FAILURE,
  COMPANY_INFO_STORE_ID_SUCCESS,
} from '../actions/companyActionTypes';

const initialState = {
  companyInfo: {},
  error: null,
  storeId: null,
  pdfMenu: {},
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_INFO_FETCH_SUCCESS:
      return {
        ...state,
        companyInfo: action.payload,
        error: null,
      };
    case COMPANY_INFO_FETCH_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case COMPANY_INFO_STORE_ID_SUCCESS:
      return {
        ...state,
        storeId: action.payload,
        error: null,
      };
    case COMPANY_INFO_STORE_ID_FAILURE:
      return {
        ...state,
        storeId: null,
        error: action.payload.error,
      };
    case COMPANY_INFO_PDF_MENU_SUCCESS:
      return {
        ...state,
        pdfMenu: action.payload,
        error: null,
      };
    case COMPANY_INFO_PDF_MENU_FAILURE:
      return {
        ...state,
        pdfMenu: {},
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default companyReducer;

export const getCompanyInfo = (state) => state.companyReducer;