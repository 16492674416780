import {ThemeProvider} from '@mui/material/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from "prop-types";
import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {loadLanguages, multilanguage} from 'redux-multilanguage';

// Css Import
import './assets/scss/app.scss';

import {theme} from './theme';
import Urls from "./Urls";
import prerender from './utils/prerender';

// Analytics
import ReactGA from "react-ga4";

ReactGA.initialize('G-W4CYLRR8E6');
ReactGA.send(window.location.pathname);


const repoPrompt = `
__  __  __
           .,-:;//;:=,
        . :H@@@MM@M#H/.,+%;,
     ,/X+ +M@@M@MM%=,-%HMMM@X/,
    -+@MM; $M@@MH+-,;XMMMM@MMMM@+-
   ;@M@@M- XM@X;. -+XXXXXHHH@M@M#@/.
 ,%MM@@MH ,@%=            .---=-=:=,.
 -@#@@@MX .,              -%HX$$%%%+;
=-./@M@M$                  .;@MMMM@MM:
X@/ -$MM/                    .+MM@@@M$
,@M@H: :@:                    . -X#@@@@-
,@@@MMX, .                    /H- ;@M@M=
.H@@@@M@+,                    %MM+..%#$.
/MMMM@MMH/.                  XM@MH; -;
 /%+%$XHH@$=              , .H@@@@MX,
  .=--------.           -%H.,@@@@@MX,
  .%MM@@@HHHXX$$$%+- .:$MMX -M@@MM%.
    =XMMM@MM@MM#H;,-+HMM@M+ /MMMX=
      =%@M@M#@$-.=$@MM@@@M; %M%=
        ,:+$+-,/H#MMMMMMM@- -,
              =++%%%%+/:-.
__  __  __
\n\nTaking a peek huh? Check out the source code: https://github.com/delandcaglar
\n\nFor more information, visit: https://delandcaglar.com
`;


const App = (props) => {
  useEffect(() => {
    if (!prerender) {
      console.info(`${repoPrompt}\n\n`);
    }
    window.history.scrollRestoration = 'manual';
  }, []);

  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require('./translations/english.json'),
          fn: require('./translations/french.json'),
          de: require('./translations/germany.json'),
          tr: require('./translations/turkish.json'),
        },
      }),
    );
  });

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Urls/>
      </ThemeProvider>
    </React.StrictMode>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(multilanguage(App));